@import '../../assets/styles/media';

.notifications-page {

  &__header, &__body {
    @media screen and (max-width: $sm-breakpoint) {
      padding: 0 1rem;
    }
  }

  .notifications-personal {
    display: flex;
    margin-top: 3rem;
    margin-bottom: 1.8rem;
    line-height: 155%;

    @media screen and (max-width: $md-breakpoint) {
      flex-direction: column;
      margin-top: 2rem;
      margin-bottom: 1.4rem;
    }

    &__item:first-of-type {
      margin-right: 3rem;

      @media screen and (max-width: $md-breakpoint) {
        margin-bottom: 1rem;
      }
    }

    &__value:last-of-type {
      display: flex;
      align-items: flex-start;

      @media screen and (max-width: $md-breakpoint) {
        word-break: break-word;
      }
    }

    &__value:last-of-type i {
      padding-left: 5px;
    }

    &__subtitle {
      color: var(--dark-gray-color);
      font-weight: 500;
      font-size: 1rem;
    }

    &__value {
      font-size: 1.1rem;
    }
  }

  &__email-remember {
    color: var(--primary-red-color);
    border-top: 1px solid var(--light-gray-color);
    padding-top: 2rem;
    margin-top: 2rem;
  }
}