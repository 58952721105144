@import '../../../assets/styles/media';

.trainer-history{

  &__title{
    font-weight: bold;
    font-size: 1.4rem;
    line-height: 150%;
    margin: 0;

    @media screen and (max-width: $md-breakpoint) {
      font-size: 1.2rem;
    }

    @media screen and (max-width: $sm-breakpoint) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  &__filters{
    margin-top: 2rem;

    @media screen and (max-width: $sm-breakpoint) {
      padding-left: 1rem;
      padding-right: 1rem;
      margin-top: 1.2rem;
    }
  }

  &__table{
    margin-top: 2rem;

    @media screen and (max-width: $sm-breakpoint) {
      margin-top: 1.2rem;
    }
  }
}