@import '../../../assets/styles/media';

.custom-date-input{
  width: 100%;
  padding: 0.9rem 0;

  &__label{
    display: block;
    font-weight: 500;
    padding-bottom: 10px;
    font-size: 0.9rem;
  }

  .error-text{
    font-size: 0.9rem;
  }
}

.date-input{
  width: 100%;
  max-width: 545px;
  height: 55px;
  font-size: 1rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--primary-white-color);
  border: 1px solid var(--primary-gray-color);
  border-radius: 15px;
  overflow: hidden;
  position: relative;

  &__text{
    width: 85%;
    height: 100%;
    border: none;
    background: none;
    padding: 1.1rem 1.25rem;
  }

  &__date{
    position: absolute;
    top: 0;
    right: 0;
    width: 15%;
    height: 100%;
    appearance: none;
    background: none;
    border: none;
    opacity: 0;
    z-index: 2;
  }

  &__icon{
    position: absolute;
    top: 0;
    right: 0;
    width: 15%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
  }

  &.error{
    border-color: var(--primary-red-color);
  }
}