@import '../../../assets/styles/media';

.trainer-register{
  &__form{
    max-width: 545px;

    .custom-checkbox-wrapper{
      padding-bottom: 3px;
    }
  }

  &__section-delete{
    position: absolute;
    top: 0;
    right: -50px;
    height: 100%;
    padding: 10px;
    display: none;
    justify-content: flex-start;
    align-items: center;

    button{
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 7px;
      margin-top: -40px;
      background: var(--primary-white-color);
      border-radius: 10px;
      border: none;
      z-index: 1;
      transition: var(--all-transition);
      cursor: pointer;

      &:hover {
        background: var(--light-gray-color);
      }

      @media screen and (max-width: $md-breakpoint) {
        background: var(--light-gray-color);
      }
    }
  }

  &__section{
    border-top: 1px solid var(--primary-gray-color);
    display: grid;
    grid-gap: 0;
    //grid-template-columns: repeat(2, 1fr);
    grid-template-columns: 60% 1fr;
    position: relative;

    &--active{
      @media screen and (max-width: $md-breakpoint) {
        .input-wrapper, .input-dadata-wrapper {
          max-width: 85%;
        }
      }
    }

    @media screen and (min-width: $md-breakpoint) {
      &:hover{
        .trainer-register__section-delete{
          display: flex;
        }
      }

      .input-wrapper input {
        border-bottom-left-radius: 0;
        border-top-left-radius: 0;
      }

      .input-dadata-wrapper input {
        border-bottom-right-radius: 0;
        border-top-right-radius: 0;
      }
    }

    @media screen and (max-width: $md-breakpoint) {
      grid-gap: 0;
      grid-template-columns: repeat(1, 1fr);

      .input-wrapper{
        order: 1;
      }
      .input-dadata-wrapper{
        order: 2;
      }

      .trainer-register__section-delete{
        right: 0;
        display: flex;
      }
    }
  }

  &__sections{
    .error-text{
      font-size: .9rem !important;
      margin-top: 0;
      padding-bottom: 1rem;
    }
  }

  &__sections-title{
    display: block;
    font-size: .9rem;
    font-weight: 500;
    margin: .5rem 0 .75rem 0;
  }

  &__sections-button{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin-top: .5rem;
    margin-bottom: 1rem;
    border-radius: 15px;
    border: 2px dashed var(--primary-gray-color);
    box-shadow: 0 5px 30px rgba(146, 146, 157, 0.1);
    background: var(--primary-white-color);
    color: var(--primary-red-color);
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    text-align: center;
    transition: var(--all-transition);
    cursor: pointer;

    &:hover{
      background: var(--light-gray-color);
    }

    &:active{
      background: var(--primary-gray-color);
    }

    @media screen and (max-width: $md-breakpoint) {
      font-size: .9rem;
      padding: 1rem;
    }
  }

  &__button{
    max-width: 215px;
    margin-top: 1.5rem !important;

    @media screen and (max-width: $sm-breakpoint) {
      max-width: 100%;
    }
  }

  &__link{
    display: block;
    font-size: 1.1rem !important;
    font-weight: normal !important;
    line-height: 150% !important;
    padding-left: 2rem !important;

    @media screen and (max-width: $sm-breakpoint){
      font-size: .9rem !important;
    }
  }
}