@import '../../../assets/styles/media';

.trainer-info-card{
  width: 100%;
  max-width: 375px;
  padding: 1rem 1.2rem;
  background: var(--primary-white-color);
  border: 1px solid var(--primary-gray-color);
  box-shadow: 0 5px 30px rgba(146, 146, 157, 0.1);
  border-radius: 15px;

  &__income{
    font-weight: 600;
    font-size: 1.1rem;
    line-height: 150%;
    padding-bottom: 2rem;

    @media screen and (max-width: $sm-breakpoint) {
      font-size: 1rem;
    }
  }
  &__income-sum{
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 150%;
    color: var(--primary-red-color);

    @media screen and (max-width: $sm-breakpoint) {
      font-size: 1.2rem;
    }
  }

  &__ref-code{
    font-size: 1rem;
    line-height: 150%;
    color: var(--dark-gray-color);
  }
  &__code{
    font-weight: 500;
    line-height: 150%;
    color: var(--primary-blue-color);
    cursor: pointer;

    &:hover{
      text-decoration: underline;
    }

    @media screen and (max-width: $sm-breakpoint) {
      display: block;
    }
  }

  @media screen and (max-width: $sm-breakpoint) {
    max-width: 100%;
  }
}