@import '../../../assets/styles/media';

.favorites-card{
  width: 100%;
  max-width: 280px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: var(--primary-white-color);
  padding: .9rem;
  border: 1px solid var(--light-gray-color);
  border-radius: 25px;
  transition: var(--all-transition);
  overflow: hidden;

  &:hover{
    box-shadow: 0 5px 30px rgba(146, 146, 157, 0.1);
  }

  &__image{
    display: block;
    width: 120px;
    height: 120px;
    margin: 0 auto;
    position: relative;

    img{
      width: 100%;
      height: 100%;
      object-fit: contain;
    }

    @media screen and (max-width: $sm-breakpoint){
      width: 100px;
      height: 100px;
    }
  }

  &__site{
    display: block;
    width: 100%;
    margin-top: 10px;
    font-size: 12px;
    line-height: 150%;
    color: var(--primary-red-color);
  }

  &__title{
    display: block;
    width: 100%;
    margin: 5px 0 0 0;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    padding: 0;
  }

  &__bottom{
    width: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding-top: 1rem;
  }

  &__price{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  &__price-old{
    font-size: 14px;
    line-height: 150%;
    text-decoration-line: line-through;
    color: var(--dark-gray-color);
  }
  &__price-current{
    font-weight: bold;
    font-size: 16px;
    line-height: 150%;
    color: var(--primary-red-color);
  }

  &__favorite{
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    margin-left: auto;
    border-radius: 10px;
    border: 1px solid rgba(211, 42, 41, 0);
    background: rgba(211, 42, 41, 0.1);
    cursor: pointer;
    transition: var(--all-transition);

    &:hover{
      border-color: var(--primary-red-color);
    }
    &:active{
      background: rgba(211, 42, 41, 0.2);
    }

    &--not{
      background: var(--light-gray-color);
      border-color: var(--light-gray-color);

      &:hover{
        border-color: var(--primary-black-color);
      }
      &:active{
        background: var(--primary-gray-color);
      }
    }
  }

  &__available{
    font-size: 14px;
    line-height: 150%;
    color: var(--dark-gray-color);
  }
}