@import '../../../assets/styles/media';

.switcher-label{
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;

  &__text{
    font-size: 14px;
    padding-left: 10px;
  }

  &--top{
    flex-direction: column;
    align-items: flex-start;
    padding: .9rem 0;

    .switcher-label__text{
      display: block;
      font-weight: 500;
      font-size: .9rem;
      padding-bottom: 10px;
      padding-left: 0;
    }

    @media screen and (max-width: $sm-breakpoint){
      padding: .6rem 0;
    }
  }

  &:hover{
    .switcher__slider{
      border-color: var(--primary-black-color);
    }
  }
}

.switcher{
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;

  &__input{
    opacity: 0;
    width: 0;
    height: 0;

    &:checked + .switcher__slider{
      background-color: var(--primary-red-color);
      border-color: var(--primary-red-color);
    }

    &:checked + .switcher__slider:before{
      transform: translateX(22px);
    }
  }

  &__slider{
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: var(--primary-gray-color);
    transition: .3s;
    border: 1px solid var(--primary-gray-color);
    border-radius: 50px;

    &:before{
      position: absolute;
      content: "";
      height: 19px;
      width: 19px;
      left: 4px;
      bottom: 2px;
      background-color: var(--primary-white-color);
      transition: .3s;
      border-radius: 50%;
    }
  }
}