.replacement-email {

  &_btn {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    padding: 0;
    border: none;
    cursor: pointer;
    color: #D32A29;
    background-color: transparent;
  }
}