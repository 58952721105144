@import '../../../assets/styles/media';

.order-basket {

  &__title {
    font-weight: 700;
    margin: 0;

    @media screen and (max-width: $sm-breakpoint) {
      padding: 0 1rem;
    }
  }

  .order-products {

    @media screen and (max-width: $sm-breakpoint) {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      padding: 0 1rem;
    }
  }

  .order-product {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 1.8rem;
    line-height: 150%;

    @media screen and (max-width: $sm-breakpoint) {
      width: 45%;
      flex-direction: column;
      justify-content: flex-start;

      &:nth-last-of-type(-n+2) {
        margin-bottom: 0;
      }
    }

    &__main {
      display: flex;
      justify-content: flex-start;

      @media screen and (max-width: $sm-breakpoint) {
        width: 100%;
        flex-direction: column;
      }
    }

    &__info {
      margin: 0 1rem;
      max-width: 300px;

      @media screen and (max-width: $sm-breakpoint) {
        margin: 0;
      }
    }

    &__name {
      word-break: break-word;
      font-weight: 700;
    }

    &__price {
      font-size: .9rem;
      font-weight: 500;

      @media screen and (max-width: $sm-breakpoint) {
        margin: .5rem 0 .7rem 0;
      }

      span {
        font-weight: 700;

        @media screen and (max-width: $sm-breakpoint) {
          font-weight: 500;
        }
      }
    }

    &__image {
      width: 100px;
      height: 100px;
      min-width: 100px;
      min-height: 100px;
      background: var(--primary-white-color);
      border: 1px solid var(--light-gray-color);
      border-radius: 6px;

      @media screen and (max-width: $sm-breakpoint) {
        width: 100%;
        height: 130px;
        margin-bottom: 1rem;
      }

      a {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: inherit;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }

    &__review {
      font-size: 1rem;
      text-align: right;

      @media screen and (max-width: $sm-breakpoint) {
        font-size: .9rem;
        text-align: left;
      }
    }
  }
}