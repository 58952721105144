@import './../../assets/styles/media';

.header{
  width: 100%;
  padding: 20px 0;
  background: var(--primary-white-color);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  box-shadow: 0 5px 30px rgba(146, 146, 157, 0.1);

  @media screen and (max-width: $md-breakpoint){
    padding: 10px 0;
  }

  &__logo{
    display: inline-flex;

    img{
      width: 100%;
      max-width: 109px;
    }
  }

  &__menu{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  &__menu-item{
    display: block;
    background: none;
    border: none;
    padding: 0 15px;
    cursor: pointer;
    transition: var(--all-transition);

    &:last-child{
      padding-right: 0;
    }

    &.active{
      .header__menu-item-icon svg{
        stroke: var(--primary-red-color);
      }
    }

    &:hover{
      .header__menu-item-icon svg{
        stroke: var(--primary-red-color);
      }
    }
  }

  &__menu-item-icon{
    display: flex;
    justify-content: center;

    path{
      transition: var(--all-transition);
    }
  }

  &__menu-item-text{
    display: block;
    font-weight: 500;
    font-size: 14px;
    margin-top: 5px;
  }
}
