:root{
    // Colors
    --primary-black-color: #2B2A29;
    --dark-black-color: #111;
    --primary-red-color: #D32A29;
    --dark-red-color: #BC2828;
    --super-dark-red-color: #A80B0A;
    --light-red-color: #F4AC6D;
    --primary-yellow-color: #F6D93E;
    --dark-yellow-color: #E76021;
    --light-yellow-color: #F8EF88;
    --primary-gray-color: #D7D7D7;
    --dark-gray-color: #92929D;
    --light-gray-color: #F1F1F5;
    --primary-blue-color: #2787F5;
    --primary-white-color: #FEFEFE;

    // Other
    --all-transition: all .2s linear;

    //Fancybox
    --fancybox-accent-color: var(--primary-blue-color, #2787F5);
}