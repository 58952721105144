@import '../../assets/styles/auth';
@import '../../assets/styles/media';

.password-recovery{
  width: 100%;

  &__text{
    font-weight: 400;
    font-size: 20px;
    line-height: 150%;

    @media screen and (max-width: $md-breakpoint){
      font-size: 16px;
    }
  }
}