@import '../../assets/styles/media';

.number-menu {
  position: absolute;
  right: 15px;
  padding: 3px 11px;
  color: #FFFFFF;
  font-size: .9rem;
  text-align: center;
  background: var(--primary-red-color);
  border-radius: 10px;

  @media screen and (max-width: $xl-breakpoint){
    font-size: .8rem;
    padding: 3px 8px;
  }
}