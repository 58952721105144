@import '../../../assets/styles/media';

.modal-edit-review{
  width: 100%;
  max-width: 645px;
  transition: var(--all-transition);

  &__close{
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  &__close-icon{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    cursor: pointer;
  }

  &__form{
    width: 100%;
    max-height: 90vh;
    padding: 50px;
    margin-top: 5px;
    background: var(--primary-white-color);
    border-radius: 15px;
    overflow-x: hidden;
    overflow-y: auto;

    @media screen and (max-width: $sm-breakpoint){
      padding: 2rem 1rem;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &__title{
    font-weight: bold;
    font-size: 18px;
    line-height: 150%;
    padding-bottom: 2rem;

    @media screen and (max-width: $sm-breakpoint){
      font-size: 17px;
    }
  }

  &__rules-text{
    font-size: 18px;
    line-height: 150%;
    margin-top: 1rem;

    a {
      color: var(--primary-red-color);
    }

    @media screen and (max-width: $sm-breakpoint){
      font-size: 1rem;
    }
  }

  &__submit-wrap{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-top: 1rem !important;
  }
  &__submit{
    max-width: 220px !important;
    height: 50px !important;
    padding: 10px !important;
    font-size: 1rem !important;

    @media screen and (max-width: $sm-breakpoint){
      max-width: 100% !important;
    }
  }
}

.modal-edit-review-animate-enter {
  opacity: 0;
}
.modal-edit-review-animate-enter-active {
  opacity: 1;
  transition: opacity 300ms, transform 300ms;
}
.modal-edit-review-animate-exit {
  opacity: 1;
}
.modal-edit-review-animate-exit-active {
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
}