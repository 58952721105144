@import '../../../assets/styles/media';

.order-info {
  margin: 3rem 0;

  @media screen and (max-width: $sm-breakpoint) {
    margin: 2rem 0 3rem 0;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    @media screen and (max-width: $sm-breakpoint) {
      flex-direction: column;
      align-items: flex-start;
      padding: 0 1rem;
    }
  }

  &__title {
    font-weight: 700;
    margin: 0;
  }

  &__site {
    color: #727275;
    font-weight: 500;
    padding-left: 1rem;

    @media screen and (max-width: $sm-breakpoint) {
      padding: 0;
    }
  }

  &__items {
    @media screen and (max-width: $sm-breakpoint) {
      padding: 0 1rem;
    }
  }

  &__item {
    display: flex;
    align-items: flex-start;
    margin-bottom: 1.6rem;

    @media screen and (max-width: $sm-breakpoint) {
      flex-direction: column;
    }
  }

  &__icon {
    margin-top: 4px;

    @media screen and (max-width: $sm-breakpoint) {
      margin-top: 0;
    }
  }

  &__main {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-left: .9rem;
    margin-bottom: .3rem;

    @media screen and (max-width: $sm-breakpoint) {
      padding-left: 0;
    }

    hr {
      margin: 0;
    }
  }

  &__subtitle {
    font-weight: 700;
    font-size: 1.1rem;
  }

  &__data {
    color: #727275;
    font-weight: 500;
  }

  &__data-title {
    color: inherit;
    font-weight: 700;
  }

  &__payment-type {
    display: flex;
    align-items: center;
    font-size: 1.1rem;
    font-weight: 500;
    padding: .7rem 0;

    @media screen and (max-width: $md-breakpoint) {
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__payment-info {
    display: flex;
    align-items: center;
  }

  &__payment-link {
    display: block;
    width: fit-content;

    @media screen and (max-width: $sm-breakpoint) {
      width: 100%;
    }
  }

  &__payment-change {
    margin-left: 1.2rem;
  }

  &__payment-button {
    width: 190px !important;
    height: 55px !important;
    font-size: 1rem !important;
    margin-bottom: 1rem;

    @media screen and (max-width: $sm-breakpoint) {
      width: 100% !important;
    }
  }

  &__trait,
  &__payment-sum,
  &__delivery-price {
    font-weight: 700;
  }

  &__must-pay {
    color: var(--primary-red-color);
    font-weight: 700;
  }

  &__payment-status {
    display: inline-block;

    i {
      display: flex;
      align-items: center;
      padding-left: 5px;
    }
  }

  &__overall {
    width: 320px;
    margin-top: 3rem;

    @media screen and (max-width: $md-breakpoint) {
      width: 100%;
    }

    @media screen and (max-width: $sm-breakpoint) {
      padding: 0 1rem;
    }

    hr {
      margin-bottom: .8rem;
    }
  }
}