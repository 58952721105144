@import '../../../assets/styles/media';

.favorites-list{
  width: 100%;
  padding-top: 30px;

  &__wrap{
    display: grid;
    grid-gap: 1.2rem;
    grid-template-columns: repeat(4, 1fr);

    @media screen and (max-width: $xxl-breakpoint){
      grid-gap: 1rem;
      grid-template-columns: repeat(3, 1fr);
    }

    @media screen and (max-width: $md-breakpoint){
      grid-gap: .5rem;
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: $sm-breakpoint){
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
}