@import '../../assets/styles/media';

.modal-replacement-phone {
  &__resend--btn {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    padding: 0;
    border: none;
    cursor: pointer;
    color: #D32A29;
    background-color: transparent;
    margin-bottom: 30px;
  }

  &__resend--text {
    display: block;
    margin-bottom: 30px;
  }

  &__sender-phone {
    margin-top: 30px;
    margin-bottom: 15px;

    span {
      font-weight: bold;
    }
  }
}