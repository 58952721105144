@import '../../assets/styles/media';
@import '../../assets/styles/button';

.profile-data{
  width: 100%;

  &__body{
    padding-top: 1rem;
  }

  &__item{
    margin-bottom: 1rem;

    &:last-child{
      margin-bottom: 0;
    }
  }
  &__item-title{
    display: block;
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: var(--dark-gray-color);
    text-transform: uppercase;
  }
  &__item-value{
    display: block;
    font-size: 18px;
    font-weight: 500;
    line-height: 150%;
    margin-top: 5px;
    position: relative;

    &--icon {
      position: absolute;
      top: 2px;
      left: 275px;
    }

    i{
      display: flex;
      align-items: center;
      padding-left: 5px;
    }

    @media screen and (max-width: $sm-breakpoint){
      font-size: 16px;
    }
  }

  &__edit-button{
    background: none;
    border: none;
    padding: 0;
    font-weight: 500;
    font-size: 18px;
    color: var(--primary-blue-color);
    margin-top: 2rem;
    transition: var(--all-transition);
    cursor: pointer;

    &.active{
      color: var(--primary-red-color);
    }

    &:hover{
      color: var(--primary-black-color);
    }

    @media screen and (max-width: $sm-breakpoint){
      font-size: 16px;
    }
  }

  &__form{
    width: 100%;
    max-width: 545px;
  }

  &__group-inputs{
    display: grid;
    grid-gap: .5rem;
    grid-template-columns: repeat(3, 1fr);

    @media screen and (max-width: $sm-breakpoint){
      grid-gap: 0;
      grid-template-columns: repeat(1, 1fr);
    }
  }

  @media screen and (max-width: $sm-breakpoint){
    padding: 0 1rem;
  }
}