@import '../../../assets/styles/media';

.order-card {
  position: relative;
  display: flex;
  justify-content: space-between;
  background: #F8F8F8;
  padding: 1.2rem;
  margin-bottom: 2rem;
  line-height: 150%;
  border: 1px solid var(--light-gray-color);
  border-radius: 6px;
  transition: var(--all-transition);

  &:hover{
    box-shadow: 0 5px 30px rgba(146, 146, 157, 0.1);
  }

  @media screen and (max-width: $md-breakpoint) {
    flex-direction: column;
    height: unset;
    padding: 1.2rem 1rem;
  }

  .order-card-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, .6);
  }

  &__left-block,
  &__right-block {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__right-block {
    width: 43%;
    text-align: right;

    @media screen and (max-width: $md-breakpoint) {
      width: 100%;
      text-align: left;
    }
  }

  &__site {
    color: var(--primary-red-color);
    font-size: .9rem !important;
    font-weight: 500 !important;
    padding-left: .8rem !important;

    @media screen and (max-width: $md-breakpoint) {
      display: none;
    }
  }

  &__number,
  &__price {
    font-size: 1.1rem;
    font-weight: 700;
    margin-bottom: .4rem;
  }

  &__status,
  &__date {
    font-weight: 500;
    font-size: 1rem;
  }

  &__images {
    display: flex;

    @media screen and (max-width: $md-breakpoint) {
      overflow-x: auto;
      white-space: nowrap;
      padding: .9rem 0;
    }
  }

  &__image {
    min-width: 80px;
    min-height: 80px;
    width: 80px;
    height: 80px;
    background: var(--primary-white-color);
    border: 1px solid var(--light-gray-color);
    border-radius: 6px;
    margin-right: 1rem;
    z-index: 1;

    &_dim {
      opacity: .4;
    }

    @media screen and (max-width: $xl-breakpoint) and (min-width: $md-breakpoint) {
      min-width: 70px;
      min-height: 70px;
      width: 70px;
      height: 70px;
      margin-right: .8rem;
    }

    @media screen and (max-width: $sm-breakpoint) {
      margin-right: .8rem;
    }

    a {
      display: block;
      width: 100%;
      height: 100%;
      border-radius: inherit;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__more {
    display: flex;
    justify-content: center;
    align-items: center;
    min-width: 50px;
    height: 80px;
    background-color: var(--primary-white-color);
    border: 1px solid var(--light-gray-color);
    border-radius: 6px;
    font-weight: 500;

    @media screen and (max-width: $xl-breakpoint) and (min-width: $md-breakpoint) {
      height: 70px;
    }
  }

  &__empty-basket {
    flex-basis: 40%;
    color: var(--primary-red-color);
    font-weight: 700;
    padding: 1.6rem 0;
  }

  &__buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 2.6rem;

    @media screen and (max-width: $xl-breakpoint) and (min-width: $md-breakpoint) {
      margin-top: 1rem;
    }

    @media screen and (max-width: $md-breakpoint) {
      flex-direction: column;
      margin-top: 1.5rem;
    }
  }

  &__payment-link {
    display: block;
    width: fit-content;
    margin-right: 1rem;

    @media screen and (max-width: $md-breakpoint) {
      width: 100%;
      margin-right: 0;
    }
  }

  &__button {
    width: 150px !important;
    height: 55px !important;
    font-size: 1rem !important;

    @media screen and (max-width: $xl-breakpoint) and (min-width: $md-breakpoint) {
      width: 120px !important;
      height: 50px !important;
      font-size: .9rem !important;
    }

    @media screen and (max-width: $md-breakpoint) {
      width: 100% !important;
    }
  }

  &__button:last-child {
    margin-right: 0;
  }

  &__detail-link {
    z-index: 1;
  }
}