@import '../../assets/styles/media';

.sidebar{
  width: 100%;
  position: sticky;
  top: 30px;

  /*PROFILE*/
  &__profile{
    width: 100%;
    padding: 15px;
    box-shadow: 0px 5px 30px rgba(146, 146, 157, 0.1);
    border-radius: 15px;
    background: var(--primary-white-color);
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;

    @media screen and (max-width: $sm-breakpoint){
      border-radius: 0;
    }

    hr{
      width: 112%;
      margin-top: 2rem;
    }
  }
  &__profile-avatar{
    width: 100px;
    height: 100px;
    background: var(--light-red-color);
    border-radius: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-weight: 500;
    font-size: 36px;
    text-align: center;
    color: var(--primary-white-color);
  }
  &__profile-name{
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    margin-top: 15px;
  }
  &__profile-email{
    font-size: 14px;
    text-align: center;
    color: var(--dark-gray-color);
    margin-top: 5px;
  }
  &__profile-link{
    width: 100%;
    margin-top: -1.9rem;
    text-align: center;

    @media screen and (max-width: $sm-breakpoint){
      margin-top: -1rem;
    }
  }

  /*MENU*/
  &__menu{
    width: 100%;
    padding: 15px 0;
    box-shadow: 0px 5px 30px rgba(146, 146, 157, 0.1);
    border-radius: 15px;
    background: var(--primary-white-color);
    margin-top: 1.5rem;

    @media screen and (max-width: $sm-breakpoint){
      border-radius: 0;
      margin-top: 1rem;
    }
  }
  &__menu-item{
    width: 100%;
    display: flex;
    align-items: center;
    padding: 10px 15px;
    position: relative;
    
    &:before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 3px;
      height: 100%;
      background: var(--primary-red-color);
      border-radius: 50px;
      opacity: 0;
      transition: var(--all-transition);
    }

    &:hover{
      &:before{
        opacity: .3;
      }
    }

    &.active{
      .sidebar__menu-item-icon svg{
        stroke: var(--primary-red-color);
      }
      .sidebar__menu-item-text{
        color: var(--primary-red-color);
      }
      &:before{
        opacity: 1;
      }
    }
  }
  &__menu-item-icon{
    padding-right: 15px;
  }
  &__menu-item-text{
    display: block;
    font-weight: 500;
    font-size: 16px;
  }

  @media screen and (max-width: $lg-breakpoint){
    position: static;
  }
}