@import '../../assets/styles/media';

.invite-banner{
  width: 100%;
  padding: 30px;
  background: var(--primary-red-color);
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  z-index: 1;

  &:before{
    content: '';
    position: absolute;
    bottom: 0;
    right: 0;
    width: 100%;
    max-width: 446px;
    height: 100%;
    max-height: 288px;
    background-image: url('../../assets/images/pattern-card.png');
    background-size: cover;
    background-position: bottom right;
    background-repeat: no-repeat;
    z-index: -1;
  }

  &__title{
    font-weight: bold;
    font-size: 24px;
    line-height: 150%;
    color: var(--primary-white-color);
    margin: 0;

    @media screen and (max-width: $sm-breakpoint){
      font-size: 22px;
    }
  }

  &__text{
    margin: 1rem 0;
    font-size: 16px;
    line-height: 150%;
    color: var(--primary-white-color);
  }

  &__button{
    display: inline-flex;
    padding-top: 1rem;
  }

  &--dark{
    background: var(--primary-black-color);
  }

  @media screen and (max-width: $sm-breakpoint){
    border-radius: 0;
    padding-left: 1rem;
    padding-right: 1rem;
  }
}