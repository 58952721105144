@import '../../assets/styles/media';

/*BUTTON*/
.button{
  width: 100%;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 40px;
  margin-top: .5rem;
  text-align: center;
  border-radius: 15px;
  border: none;
  background: var(--primary-black-color);
  font-weight: 500;
  font-size: 18px;
  color: var(--primary-white-color);
  transition: var(--all-transition);
  cursor: pointer;

  @media screen and (max-width: $sm-breakpoint) {
    font-size: 0.9rem;
    margin-top: .4rem;
    padding: 15px;
    height: 50px;
  }

  &:hover{
    background: var(--dark-black-color);
  }

  &:active{
    background: var(--dark-black-color);
    box-shadow: 0 0 0 0.25rem rgb(0 0 0 / 50%);
  }

  &:disabled{
    opacity: .6;
    cursor: not-allowed;
  }

  &__red{
    background: var(--primary-red-color);

    &:hover{
      background: var(--dark-red-color);
    }

    &:active{
      background: var(--dark-red-color);
      box-shadow: 0 0 0 0.25rem rgb(211 42 41 / 50%);
    }
  }

  &__white{
    background: var(--primary-white-color);
    color: var(--primary-black-color);

    &:hover{
      background: var(--light-gray-color);
    }

    &:active{
      background: var(--primary-gray-color);
    }
  }
}