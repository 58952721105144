@import '../../assets/styles/media';

.modal-replacement-email {
  padding: 50px 50px 50px 50px;
  width: 645px;
  background: var(--primary-white-color);
  border-radius: 15px;
  position: relative;

  &__close-icon {
    position: absolute;
    top: -45px;
    right: -9px;
    cursor: pointer;

    svg path {
      stroke: var(--primary-white-color);
    }

    @media screen and (max-width: $sm-breakpoint){
      right: 5px;
    }
  }

  &__title {
    font-weight: bold;
    margin-bottom: 20px;
  }

  &__submit-btn {
    display: flex;
    justify-content: flex-end;

    @media screen and (max-width: $sm-breakpoint) {
      justify-content: flex-start;
    }
  }

  &__inner-block {
    @media screen and (max-width: $sm-breakpoint) {
      max-height: 80vh;
      padding: 2px 2px 60px 2px;
      overflow-y: auto;
    }
  }

  @media screen and (max-width: 700px){
    width: 545px;
  }

  @media screen and (max-width: $sm-breakpoint) {
    width: 100%;
    max-height: 80vh;
    border-radius: 15px 15px 0 0;
    padding: 30px 15px 0px 15px;
  }
}

.input-wrapp {
  min-width: 170px;
  margin-right: 10px;

  @media screen and (max-width: 700px) {
    min-width: 140px;
  }

  @media screen and (max-width: $sm-breakpoint) {
    min-width: 30%;
  }
}

.button.submit-btn {
  width: 167px;
  height: 50px;

  @media screen and (max-width: $sm-breakpoint){
    width: 100%;
  }
}