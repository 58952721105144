@import '../../../assets/styles/media';

.textarea-wrapper {
  display: flex;
  flex-direction: column;
  color: var(--primary-black-color);
  font-size: 0.9rem;
  padding: 0.9rem 0;

  @media screen and (max-width: $sm-breakpoint) {
    padding: 0.6rem 0;
  }

  &.error {
    .custom-input {
      border-color: var(--primary-red-color);
    }
  }

  .custom-label {
    font-weight: 500;

    @media screen and (max-width: $sm-breakpoint) {
      font-size: 0.9rem;
    }
  }

  .custom-textarea {
    border-radius: 15px;
    max-width: 545px;
    width: 100%;
    font-size: 18px;
    margin: 10px 0 0 0;
    padding: 1.25rem;
    background-color: var(--primary-white-color);
    border: 1px solid var(--primary-gray-color);
    transition: var(--all-transition);
    resize : none;

    @media screen and (max-width: $sm-breakpoint) {
      padding: 1.1rem 1.25rem;
      font-size: 1rem;
    }

    &:hover {
      border-color: var(--primary-black-color);
    }

    &:focus {
      border-color: var(--primary-black-color);
    }

    &::placeholder {
      color: var(--primary-black-color);
      opacity: .5;
    }
  }
}