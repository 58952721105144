@import '../../assets/styles/media';

.conditions{
  width: 100%;

  &__text{
    font-size: 16px;
    line-height: 150%;
    margin: 1rem 0;
  }

  @media screen and (max-width: $sm-breakpoint){
    padding: 0 1rem;
  }
}