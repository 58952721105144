@import './fonts';
@import './vars';
@import './media';

*{
    font-family: 'Montserrat', 'Segoe UI', sans-serif;
    font-weight: normal;
    color: var(--primary-black-color);
    box-sizing: border-box;
    outline: none;
}

html {
    overflow-x: hidden;
    margin-right: calc(-1 * (100vw - 100%));
}

body{
    background-color: #FAFAFB;
    min-height: 100vh;
}

a{
    text-decoration: none;
}

.error-text {
    margin-top: 10px;
    color: var(--primary-red-color);
    line-height: 150%;

    strong {
        color: var(--primary-red-color) !important;
    }
}

.help-text {
    font-size: .9rem;
    margin-top: 10px;
    color: var(--dark-gray-color);
    line-height: 150%;
}

.required-text {
    padding: 0.9rem 0;
    font-size: 1rem;

    @media screen and (max-width: $sm-breakpoint) {
        padding: 0.6rem 0;
    }
}

.required-mark {
    color: var(--primary-red-color);
}

div:has(.grecaptcha-badge) {
    display: none !important;
}

@import './button';
@import './link';
@import './checkbox-radio-switch';