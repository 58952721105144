@import '../../assets/styles/media';

.phone-info {
  margin-bottom: 1rem;

  @media screen and (max-width: $sm-breakpoint) {
    margin-bottom: 1rem;
  }

  &__recipients {
    font-size: 1.5rem;
    line-height: 150%;
    margin-bottom: 1rem;

    @media screen and (max-width: $sm-breakpoint) {
      font-size: 1.2rem;
    }
  }

  &__number {
    font-weight: 700;

    @media screen and (max-width: $sm-breakpoint) {
      display: block;
    }
  }

  &__timer {
    font-size: 1.1rem;
    width: 100%;

    @media screen and (max-width: $sm-breakpoint) {
      font-size: 1rem;
    }
  }
}
