@import '../../assets/styles/media';

.main-block{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100vh;
  background-image: linear-gradient(rgba(43, 42, 41, .56), rgba(43, 42, 41, .56)), url('../../assets/images/lk-bg-images.jpg');
  background-repeat: no-repeat;
  background-position: center top;
  background-size: cover;
  padding-top: 35px;
  overflow-y: auto;

  @media screen and (max-width: $sm-breakpoint) {
    background-size: 110% auto;
    background-position: top;
  }
}

.main-block__images-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 55px;
  width: 100%;
  flex-wrap: wrap;

  .main-block__logo {
    margin-bottom: 0;
    max-width: 100%;
    max-height: 100px;
    height: auto;
  }
}