@import '../../assets/styles/media';

.replacement-data {
  &__item-value{
    display: block;
    font-size: 18px;
    font-weight: 500;
    line-height: 150%;
    margin-top: 5px;
    position: relative;

    &--icon {
      position: absolute;
      top: 2px;
      left: 275px;
    }

    i{
      display: flex;
      align-items: center;
      padding-left: 5px;
    }

    @media screen and (max-width: $sm-breakpoint){
      font-size: 16px;
    }
  }
}

.replacement-phone {

  &_btn {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    padding: 0;
    border: none;
    cursor: pointer;
    color: #D32A29;
    background-color: transparent;
  }
}