@import './../../assets/styles/media';

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.user-agreements {
  width: 100%;
  padding: 20px 0;
  background: var(--primary-white-color);
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
  box-shadow: 0 5px 30px rgba(146,146,157,.1);

  &__documents-list {
    display: flex;
    justify-content: space-evenly;

    &--side-bar {
      display: block;
    }
  }

  &__documents-item--side-bar {
    padding: 10px 15px;
  }

  &__documents-item-link {
    color: var(--primary-black-color) !important;
    transition: var(--all-transition);
    font-weight: 500;
    font-size: 18px;
  }

  &__documents-item-link:hover {
    text-decoration: none !important;
    cursor: pointer;
  }
}