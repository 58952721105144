.order-loading-filters-status {
  margin: .9rem 0;
  border-radius: 16px;
  max-width: 300px;
}

.orders-filters-years-skeleton {
  display: flex;
  padding: .7rem 0;
  margin-bottom: -.7rem;
}