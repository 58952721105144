@import '../../../assets/styles/media';

.custom-select-wrapper{
  color: var(--primary-black-color);
  font-size: 0.9rem;
  padding: 1rem 0;

  @media screen and (max-width: $sm-breakpoint) {
    padding: 0.6rem 0;
  }
}

.custom-select-label{
  display: inline-block;
  font-weight: 500;
  margin-bottom: 10px;
}

.custom-select{
  &__control{
    max-width: 545px;
    width: 100%;
    font-size: 1.1rem;
    padding: .7rem 1.25rem !important;
    background-color: var(--primary-white-color) !important;
    border: 1px solid var(--primary-gray-color) !important;
    border-radius: 15px !important;
    box-shadow: none !important;

    &--is-focused{
      border-color: var(--primary-black-color) !important;
    }

    &:hover{
      border-color: var(--primary-black-color) !important;
    }

    @media screen and (max-width: $sm-breakpoint) {
      padding: .5rem 1.25rem !important;
    }
  }

  &__indicator{
    cursor: pointer;
  }

  &__multi-value{
    background-color: var(--light-gray-color) !important;

    &__label{
      padding: 6px !important;
      color: var(--primary-black-color) !important;
    }

    &__remove{
      transition: var(--all-transition);
      cursor: pointer;
    }
  }

  &__value-container{
    padding: 0 !important;
  }

  &__menu{
    margin: .5rem 0 !important;
    z-index: 1000 !important;
  }
  &__option{
    transition: var(--all-transition);

    &--is-focused{
      background: var(--light-gray-color) !important;
    }

    &--is-selected{
      background: var(--primary-gray-color) !important;
      color: var(--primary-black-color) !important;
    }

    &:hover{
      background: var(--light-gray-color) !important;
      cursor: pointer;
    }

    &:active{
      background: var(--primary-gray-color) !important;
    }
  }

  &__single-value, &__placeholder{
    font-size: 1.1rem !important;

    @media screen and (max-width: $sm-breakpoint) {
      font-size: 1rem !important;
    }
  }
}