@import '../../assets/styles/media';

.empty-items{
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 1.5rem;
  border-top: 1px solid var(--light-gray-color);

  &--hide-border{
    border: none;
  }

  &__icon{
    display: flex;
    justify-content: center;
    align-items: center;
    padding-bottom: .5rem;
  }

  &__text{
    font-weight: bold;
    font-size: 18px;
    line-height: 150%;
    text-align: center;

    @media screen and (max-width: $sm-breakpoint){
      font-size: 16px;
    }
  }
}