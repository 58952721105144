@import '../../assets/styles/media';

.products-for-review{
  width: 100%;
  padding: 1rem 0;

  &--delimiters{
    padding: 0;
  }

  &__wrap{
    padding: 30px 0;
    background: var(--light-gray-color);
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 5px 30px rgba(146, 146, 157, 0.1);

    @media screen and (max-width: $sm-breakpoint){
      padding: 20px 0;
      border-radius: 0;
    }
  }

  &__head{
    width: 100%;
    padding: 0 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: $sm-breakpoint){
      padding-left: 1rem;
      padding-right: 1rem;
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
    }
  }

  &__title{
    width: 80%;
    font-weight: bold;
    font-size: 24px;
    margin: 0;

    @media screen and (max-width: $md-breakpoint){
      font-size: 18px;
    }
    @media screen and (max-width: $sm-breakpoint){
      width: 100%;
    }
  }

  &__arrows{
    width: 20%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 0 10px;

    @media screen and (max-width: $sm-breakpoint){
      width: 100%;
      justify-content: flex-start;
      margin-top: 15px;
    }
  }

  &__arrow{
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--primary-black-color);
    border: none;
    border-radius: 50px;
    cursor: pointer;
    transition: transform .1s ease-out;

    &:disabled{
      cursor: not-allowed;
      opacity: .5;
    }
  }

  &__arrow:not(.swiper-button-disabled){
    &:hover{
      transform: scale(1.1);
    }

    &:active{
      background: var(--dark-black-color);
      transform: scale(1);
    }
  }

  &__slider{
    padding-top: 30px;

    .swiper-wrapper{
      margin-left: 30px;

      @media screen and (max-width: $sm-breakpoint){
        margin-left: 0;
      }
    }

    .swiper-slide{
      width: 100%;
      max-width: 360px;
      min-width: 290px;
      user-select: none;

      &:last-of-type{
        margin-right: 60px;

        @media screen and (max-width: $sm-breakpoint){
          margin-right: 0;
        }
      }

      @media screen and (max-width: $sm-breakpoint){
        max-width: 100%;
        padding: 0 1rem;
      }
    }
  }

  &__slide{
    width: 100%;
    padding: 30px;
    background: #0d6efd;
  }

  &__loading{
    width: 100%;
    display: flex;
  }
  &__loading-item{
    margin-left: 30px;
    border-radius: 25px;
  }
}

.products-for-review-animate-enter {
  opacity: 0;
  transform: scale(0.9);
}
.products-for-review-animate-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}
.products-for-review-animate-exit {
  opacity: 1;
}
.products-for-review-animate-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}
