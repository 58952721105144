@import '../../../assets/styles/media';

.image-uploader{
  width: 100%;
  display: block;
  padding: .9rem 0;

  &__label{
    display: block;
    font-weight: 500;
    font-size: .9rem;
    padding-bottom: 10px;
  }

  &__wrap{
    display: flex;
    flex-wrap: wrap;
    gap: .5rem;
  }

  &__add{
    width: 80px;
    min-width: 80px;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--primary-white-color);
    border-radius: 15px;
    border: 1px solid var(--primary-gray-color);
    cursor: pointer;
    transition: var(--all-transition);

    &--dragging{
      border-color: var(--primary-black-color);
      width: 100%;
    }

    &:hover{
      border-color: var(--primary-black-color);
      background: var(--light-gray-color);
    }
  }
  &__add-icon{
    display: flex;
    justify-content: center;
    align-items: center;

    div{
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  &__image{
    width: 80px;
    min-width: 80px;
    height: 80px;
    position: relative;
  }

  &__image-remove{
    position: absolute;
    top: -5px;
    right: -5px;
    width: 20px;
    height: 20px;
    background: var(--light-gray-color);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
    border-radius: 5px;
    border: 1px solid var(--primary-gray-color);
    z-index: 999;
    cursor: pointer;
    transition: var(--all-transition);

    &:hover{
      background: var(--primary-gray-color);
    }
  }

  &__image-wrap{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--primary-white-color);
    border-radius: 15px;
    border: 1px solid var(--primary-gray-color);
    overflow: hidden;

    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  @media screen and (max-width: $sm-breakpoint){
    padding: .6rem 0;
  }
}