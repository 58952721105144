@import '../../../assets/styles/media';

.trainer-main{

  &__header{
    @media screen and (max-width: $sm-breakpoint) {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }

  &__card{
    padding-top: 2rem;

    @media screen and (max-width: $sm-breakpoint) {
      padding-top: 1rem;

      .trainer-info-card{
        border-radius: 0 !important;
      }
    }
  }

  &__history{
    padding-top: 3rem;
  }
}