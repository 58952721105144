@import '../../../assets/styles/media';

.trainer-history-list{
  width: 100%;

  &__item{
    width: 100%;
    padding: 1rem;
    background: var(--light-gray-color);
    margin-bottom: 1rem;

    @media screen and (min-width: $sm-breakpoint) {
      border-radius: 15px;
    }
  }

  &__item-header{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__item-date{
    font-size: 14px;
    line-height: 150%;
  }

  &__item-sum{
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
  }

  &__item-name{
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    word-break: break-all;
    margin-top: 10px;
  }

  &__item-money{
    display: flex;
    margin-top: 10px;

    span{
      font-weight: bold;
      font-size: 14px;
      line-height: 150%;
      margin-right: 10px;
    }

    &--gone{
      color: var(--primary-red-color);
    }
  }
}