@import '../../../assets/styles/media';

.favorites-filters{
  width: 100%;

  &__item{
    padding-top: .5rem;
  }
  &__item-title{
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    padding-bottom: 5px;

    &--mod{
      padding-bottom: 0;
    }
  }

  &__item-select{
    .custom-select-wrapper{
      color: var(--primary-black-color);
      font-size: 16px !important;
      padding: 0.6rem 0;
    }

    .custom-select__control{
      font-size: 16px !important;
      margin: 0;
      padding: .4rem 1.25rem !important;
    }

    .custom-select__single-value, .custom-select__placeholder{
      font-size: 16px !important;
    }
  }

  &__item-checkboxes{
    padding-top: 5px;
    max-height: 30vh;
    overflow-y: auto;

    .custom-checkbox-wrapper{
      padding: 7px 0 !important;
    }

    @media screen and (max-width: $sm-breakpoint){
      max-height: none;
    }
  }

  &__reset-button-wrap{
    margin-top: 20px;
    padding-bottom: 5px;
    display: flex;
    justify-content: flex-end;
  }
}