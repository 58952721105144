@import '../../../assets/styles/media';

.ui-rating{
  width: 100%;
  display: block;
  padding: .9rem 0;

  &__label{
    display: block;
    font-weight: 500;
    font-size: .9rem;
    padding-bottom: 10px;
  }

  @media screen and (max-width: $sm-breakpoint){
    padding: .6rem 0;
  }
}