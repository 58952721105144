@import '../../assets/styles/media';

.main{
  width: 100%;
  margin-top: 1.5rem;
  padding-bottom: 5rem;

  @media screen and (max-width: $sm-breakpoint){
    margin-top: 0;
  }
}

.wrapper{
  @media screen and (max-width: $sm-breakpoint){
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}

.content{
  width: 100%;
}

.page{
  width: 100%;
  padding: 50px 30px;
  background: var(--primary-white-color);
  box-shadow: 0 5px 30px rgba(146, 146, 157, 0.1);
  border-radius: 15px;

  @media screen and (max-width: $sm-breakpoint){
    padding: 1rem 0;
    border-radius: 0;
  }
}