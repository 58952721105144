@import '../../assets/styles/media';

.order-page {
  line-height: 200%;

  &__header {
    @media screen and (max-width: $sm-breakpoint) {
      padding: 0 1rem;
    }
  }

  hr {
    margin: .8rem 0 1.7rem 0;
  }

  .order-cancel {
    display: flex;
    justify-content: flex-start;
    margin-top: 2rem;

    @media screen and (max-width: $sm-breakpoint) {
      padding: 0 1rem;
      margin-top: 1.6rem;
    }

    &__button {
      font-size: 1rem !important;
    }
  }

  .empty-items {
    border: none !important;
  }
}