@import '../../assets/styles/media';

@mixin visible-delete-icon() {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
  border-radius: 10px;
  position: absolute;
  top: 10px;
  right: 10px;
  background: var(--primary-white-color);
  z-index: 1;
  transition: var(--all-transition);
}

.card {
  width: 100%;
  overflow: hidden;
  position: relative;
  padding: 30px 20px;
  border: 1px solid var(--primary-gray-color);
  transition: var(--all-transition);

  &-text {
    text-align: left;
  }

  &__trash-bin {
    display: none;

    @media screen and (max-width: $lg-breakpoint) {
      @include visible-delete-icon();
    }
  }

  &:hover {
    .card__trash-bin {
      @include visible-delete-icon();

      &:hover {
        background: var(--light-gray-color);
      }
    }

    border-color: var(--primary-red-color);
  }

  @media screen and (max-width: $md-breakpoint) {
    margin-right: 0;
    width: 100%;
  }
}