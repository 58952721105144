@import '../../../assets/styles/media';

.custom-checkbox-wrapper {
  display: flex;
  padding: 1rem 0;

  @media screen and (max-width: $sm-breakpoint) {
    padding: 0.7rem 0;
  }

  .custom-checkbox {
    display: block !important;
    white-space: normal !important;

    &:hover .state label:before {
      border-color: var(--primary-black-color) !important;
    }

    .state {
      margin-left: 2rem;
      padding-top: 0;

      @media screen and (max-width: $sm-breakpoint) {
        padding-top: 2px;
      }

      label {
        display: unset !important;
        font-size: 1.1rem;
        line-height: 150%;

        @media screen and (max-width: $sm-breakpoint) {
          font-size: 0.9rem;
        }

        &:before,
        &:after {
          top: 4px !important;
          width: 20px !important;
          height: 20px !important;
          border-radius: 5px !important;
          transition: var(--all-transition);
        }
      }
    }

    &.p-image {
      .state img {
        top: 6px !important;
        left: 3px !important;
        width: 15px !important;
        height: 15px !important;
        transition: var(--all-transition);
        z-index: 1 !important;
      }
    }

    &.checked  {
      .state label:before, .state label:after {
        background-color: var(--primary-red-color) !important;
      }
    }

    &__privacy-policy-block {
      padding-top: 6px;
    }

    &__link {
      color: var(--primary-black-color);
    }

    &__link:hover {
      color: var(--primary-black-color);
      text-decoration: none;
    }
  }
}



