@import '../../../assets/styles/media';

.reviews-item{
  width: 100%;
  padding: 20px;
  margin-bottom: 2rem;
  background: #F8F8F8;
  border: 1px solid var(--light-gray-color);
  border-radius: 5px;
  overflow: hidden;
  transition: var(--all-transition);

  &:last-of-type{
    margin-bottom: 0;
  }

  &:hover{
    box-shadow: 0 5px 30px rgba(146, 146, 157, 0.1);

    @media screen and (min-width: $md-breakpoint){
      .reviews-item__controls{
        opacity: 1;
        visibility: visible;
      }
    }
  }

  &-wrapper {
    display: flex;

    @media screen and (max-width: $md-breakpoint){
      flex-direction: column;
    }
  }

  &__left{
    display: flex;
    flex-direction: column;

    @media screen and (max-width: $md-breakpoint){
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
    }
  }

  &__product-image{
    width: 120px;
    min-width: 120px;
    height: 120px;
    border-radius: 10px;
    overflow: hidden;
    background: var(--primary-white-color);
    border: 1px solid var(--light-gray-color);

    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__controls{
    padding-top: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    transition: var(--all-transition);

    @media screen and (min-width: $md-breakpoint){
      opacity: 0;
      visibility: hidden;
    }
  }
  &__control{
    width: 32px;
    min-width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: var(--light-gray-color);
    border: 1px solid #E6E6E6;
    border-radius: 5px;
    transition: var(--all-transition);
    cursor: pointer;

    &:hover{
      border-color: var(--dark-gray-color);
    }

    &:active{
      background: var(--primary-gray-color);
    }

    @media screen and (max-width: $sm-breakpoint){
      width: 36px;
      min-width: 36px;
      height: 36px;
    }
  }

  &__content{
    width: 75%;
    padding-left: 1.2rem;

    @media screen and (max-width: $md-breakpoint){
      padding-left: 0;
      padding-top: 1rem;
    }
  }

  &__header{
    width: 100%;
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: $md-breakpoint){
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  &__product-info{
    width: 60%;

    @media screen and (max-width: $md-breakpoint){
      width: 100%;
    }
  }
  &__product-site{
    display: block;
    font-size: 12px;
    line-height: 150%;
    color: var(--primary-red-color);
    padding-bottom: 5px;
  }
  &__product-name{
    font-weight: bold;
    font-size: 16px;
    line-height: 150%;
    margin: 0;
  }

  &__rating-info{
    width: 40%;

    @media screen and (max-width: $md-breakpoint){
      width: 100%;
      padding-top: 1rem;
    }
  }
  &__rating{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-bottom: 10px;

    @media screen and (max-width: $md-breakpoint){
      justify-content: flex-start;
    }
  }
  &__date{
    font-size: 16px;
    line-height: 150%;
    text-align: right;

    @media screen and (max-width: $md-breakpoint){
      text-align: left;
    }
  }

  &__body{
    width: 100%;
    padding-top: 1rem;

    @media screen and (max-width: $md-breakpoint){
      padding-top: 2rem;
    }
  }
  &__comment{
    width: 100%;
    padding-bottom: 20px;

    &:last-of-type{
      padding-bottom: 0;
    }
  }
  &__comment-title{
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
    color: var(--dark-gray-color);
    padding-bottom: 5px;
    text-transform: uppercase;
  }
  &__comment-value{
    white-space: pre-line;
    font-size: 16px;
    line-height: 150%;
    word-wrap: break-word;
  }

  &__photos{
    display: flex;
    flex-wrap: wrap;
    gap: .5rem;
  }
  &__photo{
    width: 80px;
    min-width: 80px;
    height: 80px;
    border-radius: 5px;
    overflow: hidden;
    background: var(--primary-white-color);
    border: 1px solid var(--light-gray-color);
    transition: var(--all-transition);

    &:hover{
      border-color: var(--dark-gray-color);
    }

    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__review-ratings {
    display: flex;
    margin-top: 30px;
    margin-left: 135px;

    @media screen and (max-width: $md-breakpoint){
      margin-left: 0;
    }
  }

  &__admin-feedback {
    margin-top: 30px;
    margin-left: 135px;

    @media screen and (max-width: $md-breakpoint){
      margin-left: 0;
    }
  }

  @media screen and (max-width: $sm-breakpoint){
    padding: 20px 1rem;
  }
}

.review-ratings {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  border: 1px solid #E6E6E6;
  border-radius: 10px;
  font-weight: 500;
  font-size: 14px;
  line-height: 14px;
  background: var(--light-gray-color);
  color: var(--primary-black-color);
}

.review-ratings__img {
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px 6px 9px 10px;
}

.review-ratings__count {
  min-width: 9px;
  margin-right: 8px;
}

.review-ratings:not(:last-child) {
  margin-right: 15px;
}

.admin-feedback {

  &__header {
    display: flex;

    &-logo {
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #FFFFFF;
      border-radius: 10px;
      margin-right: 15px;

      img {
        height: 15px;
        width: 40px;
      }
    }

    &-content {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
    }

    &-title {
      position: relative;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: var(--primary-black-color);
      margin-right: 30px;

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        right: -25px;
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background-color: #72C472;
        background-image: url("../../../assets/images/checkmark.svg");
        background-repeat: no-repeat;
        background-position: 3px 5px;
        background-size: 10px;
        transform: translateY(-50%);

        @media screen and (max-width: 348px){
          top: 74%;
          right: 0;
          left: 88px;
        }
      }
    }

    &-subtitle {
      font-weight: 500;
      font-size: 12px;
      line-height: 150%;
      color: #D32A29;
    }
  }

  &__body {
    margin-top: 10px;
    margin-left: 25px;

    &-text {
      font-weight: 400;
      font-size: 14px;
      line-height: 150%;
      color: var(--primary-black-color);
      border-left: 1px solid var(--dark-gray-color);
      padding: 5px 0 5px 40px;
      white-space: pre-line;

      @media screen and (max-width: $sm-breakpoint){
        border-left: none;
        padding: 5px 0;
      }
    }

    @media screen and (max-width: $sm-breakpoint){
      margin-left: 0;
    }
  }
}