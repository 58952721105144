@import '../../assets/styles/media';

.notifications-section {
  &__title {
    font-size: 1.5rem;
    font-weight: 700;
    margin: 3rem 0 0 0;

    @media screen and (max-width: $sm-breakpoint) {
      margin-top: 2rem;
      font-size: 1.2rem;
    }
  }

  &__description {
    margin: 1rem 0 1.8rem 0;
  }

  .switcher-label {
    margin-top: .9rem;
  }
}