@import '../../assets/styles/media';

.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(43, 42, 41, 0.6);
  z-index: 9999;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: $sm-breakpoint){
    justify-content: flex-end;
    flex-direction: column;
  }
}