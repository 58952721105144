@import '../../../assets/styles/media';

.sort-panel{
  &__button{
    display: inline-flex;
    align-items: center;
    padding: 7px 10px;
    margin-left: -10px;
    background: var(--primary-white-color);
    border-radius: 10px;
    cursor: pointer;
    transition: var(--all-transition);
    user-select: none;
    border: none;

    &:hover{
      background: var(--light-gray-color);
    }
    &:active{
      background: var(--primary-gray-color);
    }

    @media screen and (max-width: $sm-breakpoint){
      margin-left: 0;
    }
  }
  &__button-text{
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
  }
  &__button-icon{
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 5px;
  }

  &__popup{
    position: absolute;
    bottom: 0;
    left: 0;
    transform: translateY(100%);
    display: flex;
    flex-direction: column;
    padding: 1rem 0;
    background: var(--primary-white-color);
    box-shadow: 0 5px 30px rgba(146, 146, 157, 0.3);
    border-radius: 15px;
    z-index: 1000;
    opacity: 0;
    visibility: hidden;
    transition: var(--all-transition);

    &.active{
      //z-index: 1000;
      opacity: 1;
      visibility: visible;
    }

    @media screen and (max-width: $sm-breakpoint){
      left: .5rem;
    }
  }
  &__popup-item{
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    padding: 10px 1rem;
    border: none;
    background: var(--primary-white-color);
    text-align: left;
    transition: var(--all-transition);
    cursor: pointer;
    user-select: none;

    &.active{
      background: var(--primary-gray-color);
    }

    &:hover{
      background: var(--light-gray-color);
    }
    &:active{
      background: var(--primary-gray-color);
    }
  }
}