@import '../../assets/styles/media';

.privacy-policy {

  &-container {
    padding-bottom: 5rem;
  }

  &__title {
    text-align: center;
    font-weight: bold;

    @media screen and (max-width:1200px) {
      font-size: 26px !important;
    }

    @media screen and (max-width:768px) {
      font-size: 24px !important;
    }

    @media screen and (max-width:492px) {
      font-size: 22px !important;
    }

    @media screen and (max-width:380px) {
      font-size: 20px !important;
    }
  }

  &__body {
    line-height: 30px;
  }

  &__body-title {
    text-align: center;
    font-weight: bold;

    @media screen and (max-width:492px) {
      font-size: 16px;
    }
  }

  &-link {
    cursor: pointer;
    color: #ff8c00;
    word-wrap: break-word;
  }

  @media screen and (max-width:575px) {
    padding: 0 10px;
  }
}

.secure-data__list {
  list-style-type: disc;
  margin: 40px 30px;
}

.secure-data__item:not(:last-child) {
  margin-bottom: 15px;
}