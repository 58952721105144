@import '../../assets/styles/media';

.favorites{
  width: 100%;

  &__header{
    @media screen and (max-width: $sm-breakpoint){
      padding: 0 1rem;
    }
  }
}

.favorites-filters-modal{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: none;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(43, 42, 41, .6);
  z-index: -9999;

  &__close{
    width: 100%;
    max-width: 576px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    padding: 1rem;
    background: none;
    border: none;
    outline: none;
  }

  &__wrap{
    width: 100%;
    max-width: 576px;
    max-height: calc(100vh - 60px);
    overflow-y: auto;
    background: var(--primary-white-color);
    padding: 2rem 1.5rem;
    border-radius: 15px;

    @media screen and (max-width: $sm-breakpoint) {
      padding: 1.9rem 1rem;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  &.active{
    display: flex;
    z-index: 9999;
  }

  @media screen and (max-width: $sm-breakpoint) {
    justify-content: flex-end;
  }
}