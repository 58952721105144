@font-face{
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Regular.ttf');
    font-weight: normal;
}
@font-face{
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Medium.ttf');
    font-weight: 500;
}
@font-face{
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat-Bold.ttf');
    font-weight: bold;
}