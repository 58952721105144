@import '../../assets/styles/media';

.menu{
  &__logout-btn{
    width: 100%;
    margin-top: 1rem;
    padding: 15px;
    box-shadow: 0px 5px 30px rgba(146, 146, 157, 0.1);
    background: var(--primary-white-color);

    button{
      width: 100%;
    }

    @media screen and (min-width: $sm-breakpoint){
      display: none;
    }
  }
}