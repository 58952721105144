@import '../../assets/styles/media';

.custom-link {
  color: var(--primary-red-color);
  font-weight: 500;
  font-size: 18px;
  text-align: left;
  transition: var(--all-transition);
  border: none;
  background: none;
  padding: 0;

  @media screen and (max-width: $sm-breakpoint) {
    font-size: 1rem;
  }

  &:hover {
    color: var(--super-dark-red-color);
    text-decoration: underline;
    cursor: pointer;
  }

  &__blue {
    color: var(--primary-blue-color);

    &:hover {
      color: var(--primary-black-color);
      text-decoration: none;
    }
  }
}