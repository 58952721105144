@import '../../assets/styles/media';

.addresses {
  width: 100%;

  &__body {
    padding-top: 1rem;
  }

  &__list {
    width: 100%;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(3, 1fr);

    @media screen and (max-width: $xxl-breakpoint) {
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: 771px) {
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &__button {
    font: inherit;
    color: inherit;
    cursor: pointer;
    min-width: 250px;
    line-height: 150%;
    border-radius: 15px;
    background-color: transparent;
    transition: var(--all-transition);
    background: var(--primary-white-color);
    box-shadow: 0 5px 30px rgba(146, 146, 157, 0.1);

    &-text {
      font-weight: 500;
      font-size: 16px;
      text-align: center;
      margin-top: 5px;
      transition: var(--all-transition);
      overflow-wrap: break-word;
    }
  }

  @media screen and (max-width: $sm-breakpoint) {
    padding: 0 1rem;
  }
}

.add {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  border: 3px dashed var(--primary-gray-color);
  transition: var(--all-transition);

  &:hover {
    border-color: var(--primary-red-color);
    background: var(--primary-red-color);

    svg path {
      stroke: var(--primary-white-color);
    }

    svg circle {
      stroke: var(--primary-white-color);
    }

    .add-text {
      color: var(--primary-white-color);
    }
  }

  @media screen and (max-width: $md-breakpoint) {
    width: 100%;
  }
}

.pagination-block {
  margin-top: 2rem;
}