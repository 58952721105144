@import '../../../assets/styles/media';

.input-wrapper {
  display: flex;
  flex-direction: column;
  color: var(--primary-black-color);
  font-size: 0.9rem;
  padding: 0.9rem 0;

  @media screen and (max-width: $sm-breakpoint) {
    padding: 0.6rem 0;
  }

  &.error {
    .custom-input {
      border-color: var(--primary-red-color);
    }
  }

  .custom-label {
    font-weight: 500;

    @media screen and (max-width: $sm-breakpoint) {
      font-size: 0.9rem;
    }
  }

  .custom-input {
    border-radius: 15px;
    max-width: 545px;
    width: 100%;
    font-size: 18px;
    margin: 10px 0 0 0;
    padding: 1.25rem;
    background-color: var(--primary-white-color);
    border: 1px solid var(--primary-gray-color);
    transition: var(--all-transition);

    @media screen and (max-width: $sm-breakpoint) {
      padding: 1.1rem 1.25rem;
      font-size: 1rem;
    }

    &:hover {
      border-color: var(--primary-black-color);
    }

    &:focus {
      border-color: var(--primary-black-color);
    }

    &::placeholder {
      color: var(--primary-black-color);
      opacity: .5;
    }
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }

  input[type="number"]:hover,
  input[type="number"]:focus {
    -moz-appearance: number-input;
  }

  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
