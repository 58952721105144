@import '../../assets/styles/media';

.product-for-review{
  width: 100%;
  border-radius: 25px;
  background: var(--primary-white-color);
  padding: 15px;
  overflow: hidden;

  &__info{
    width: 100%;
    display: flex;
    align-items: center;

    @media screen and (max-width: $sm-breakpoint){
      flex-direction: column;
      align-items: flex-start;
    }
  }

  &__image{
    width: 120px;
    min-width: 120px;
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;

    img{
      width: 80%;
      height: 80%;
      object-fit: contain;
    }

    @media screen and (max-width: $sm-breakpoint){
      margin: 0 auto;
    }
  }

  &__title{
    padding-left: 10px;
  }

  &__site{
    font-size: 12px;
    line-height: 150%;
    color: var(--primary-red-color);
    padding-bottom: 5px;
  }

  &__name{
    font-weight: 500;
    font-size: 14px;
    line-height: 150%;
  }

  &__buttons{
    width: 100%;
    padding-top: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: $sm-breakpoint){
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  &__feedback{
    background: none;
    border: none;
    padding: 0;
    cursor: pointer;

    div{
      display: flex;
      align-items: center;
    }

    svg path{
      transition: var(--all-transition);
    }

    &:hover{
      svg path{
        fill: var(--dark-gray-color);
      }
    }

    @media screen and (max-width: $sm-breakpoint){
      margin-top: 15px;
    }
  }
}