@import '../../assets/styles/media';

.card-code{
  width: 100%;

  &__header{
    @media screen and (max-width: $sm-breakpoint){
      padding: 0 1rem;
    }
  }

  &__body{
    width: 100%;
    padding-top: 1rem;

    @media screen and (max-width: $md-breakpoint){
      padding-top: 0;
    }
  }

  &__item{
    &:before{
      content: '';
      position: absolute;
      bottom: 0;
      right: 0;
      width: 100%;
      max-width: 446px;
      height: 100%;
      max-height: 288px;
      background-image: url('../../assets/images/pattern-card.png');
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      z-index: -1;
    }
  }

  .row{
    @media screen and (max-width: $sm-breakpoint){
      width: 100%;
      margin: 0;
    }
  }

  .column{
    @media screen and (max-width: $md-breakpoint){
      margin: 0.5rem 0;
    }

    @media screen and (max-width: $sm-breakpoint){
      padding-left: 0 !important;
      padding-right: 0 !important;

      &--info{
        order: 2;
      }

      &--barcode{
        order: 1;
      }
    }
  }

  &__text{
    font-weight: 500;
    font-size: 18px;
    line-height: 150%;
    margin-top: 1.5rem;

    @media screen and (max-width: $sm-breakpoint){
      font-size: 16px;
      margin-top: 1rem;
    }
  }

  &__trainer{
    width: 100%;
    display: flex;
    align-items: center;
    padding: 30px;
    background: rgba(188, 40, 40, 0.1);
    border-radius: 15px;
    margin-bottom: 1.6rem;

    @media screen and (max-width: $md-breakpoint){
      flex-direction: column;
      align-items: flex-start;
    }
    @media screen and (max-width: $sm-breakpoint){
      padding: 1rem;
    }
  }
  &__trainer-icon{
    min-width: 50px;
    display: flex;
    align-items: center;
  }
  &__trainer-content{
    padding-left: 1.8rem;

    @media screen and (max-width: $md-breakpoint){
      padding-left: 0;
      padding-top: .5rem;
    }
  }
  &__trainer-title{
    font-weight: 500;
    font-size: 24px;
    line-height: 150%;
    color: var(--primary-red-color);

    @media screen and (max-width: $md-breakpoint){
      font-size: 22px;
    }
  }
  &__trainer-description{
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    margin-top: 5px;

    @media screen and (max-width: $md-breakpoint){
      margin-top: .5rem;
    }
  }
}

.discount-card{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 30px;
  background: var(--primary-red-color);
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  z-index: 1;

  &__title {
    color: var(--primary-white-color);
    font-weight: 700;
    line-height: 150%;
    padding-right: 60px;
    font-size: 1.2rem;
  }

  &__logo{
    position: absolute;
    top: 30px;
    right: 30px;
    max-width: 60px;

    @media screen and (max-width: $sm-breakpoint){
      top: 20px;
      right: 1rem;
    }
  }

  &__items{
    width: 80%;
  }

  &__item{
    width: 100%;
    margin-top: 1rem;

    &:first-child{
      margin-top: 0;
    }
  }
  &__item-percent{
    color: var(--primary-white-color);
    font-weight: bold;
    font-size: 36px;
    line-height: 44px;
  }
  &__item-description{
    color: var(--primary-white-color);
    font-size: 16px;
  }

  &__binding{
    width: 100%;
    padding-top: 50px;
  }
  &__binding-title{
    color: var(--primary-white-color);
    font-size: 16px;
  }
  &__binding-phone{
    color: var(--primary-white-color);
    font-size: 18px;
    font-weight: bold;
    margin-top: 5px;
  }

  @media screen and (max-width: $sm-breakpoint){
    padding: 20px 1rem;
    border-radius: 0;
  }
}

.barcode{
  width: 100%;
  height: 100%;
  padding: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--primary-black-color);
  border-radius: 15px;
  overflow: hidden;
  position: relative;
  z-index: 1;

  &__number {
    font-size: 1.2rem;
    margin-top: .25rem;
  }

  &__element{
    padding: 15px;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    cursor: pointer;

    img{
      width: 100%;
      max-width: 260px;
    }
  }

  @media screen and (max-width: $sm-breakpoint){
    border-radius: 0;
  }
}

.barcode-modal{
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(43, 42, 41, .6);
  z-index: 9999;
  transition: var(--all-transition);
  opacity: 0;
  visibility: hidden;

  &__number{
    margin-top: .25rem;
    font-size: 1.5rem;
  }

  &__element{
    padding: 50px;
    background: var(--primary-white-color);
    border-radius: 15px;
    box-shadow: 0px 5px 30px rgba(43, 42, 41, 0.25);
    overflow: hidden;

    img{
      width: 350px;
      user-select: none;

      @media screen and (max-width: $sm-breakpoint){
        width: 100%;
        max-width: 260px;
      }
    }

    @media screen and (max-width: $sm-breakpoint){
      padding: 15px;
    }
  }

  &.active{
    opacity: 1;
    visibility: visible;
  }
}