.mobile-title{
  width: 100%;
  padding: 15px;
  background: var(--primary-white-color);
  box-shadow: 0 5px 30px rgba(146, 146, 157, 0.1);
  margin-bottom: 1rem;

  h1{
    font-weight: 600;
    font-size: 18px;
    margin: 0;
  }
}