.menu-group {
  &__title {
    font-weight: 700;
    text-transform: uppercase;
    padding: 15px 15px;
  }
}
.menu-groups{
  &--lock{
    position: relative;
    pointer-events: none;
    user-select: none;

    &:before{
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 100;
      background: rgba(#fff, .5);
    }
  }
}