@import '../../assets/styles/media';

.orders-page {
  padding-top: 35px !important;
}

.orders {
  &__header {
    @media screen and (max-width: $sm-breakpoint) {
      padding: 0 1rem;
    }
  }

  .orders-filters {
    &__statuses {
      max-width: 300px;
      width: 100%;

      .custom-select {
        &__control {
          padding-top: .5rem !important;
          padding-bottom: .5rem !important;
        }
      }
    }

    &__years {
      display: flex;
      overflow-x: auto;
      padding: .7rem 0;
      margin-bottom: -.7rem;
    }

    &__year {
      margin-right: .9rem;
    }
  }

  .empty-items {
    border: none !important;
  }
}

