@import '../../assets/styles/media';

.kid-page{
  width: 100%;

  &__delete-button-wrap{
    @media screen and (max-width: $sm-breakpoint) {
      padding: 0 1rem;
    }
  }
}