@import './src/assets/styles/media';

.confirm-email {
  display: flex;

  &__img {
    margin-right: 21px;

    @media screen and (max-width: $sm-breakpoint) {
      text-align: center;
    }
  }

  &__title {
    font-weight: bold;
    font-size: 18px;
    color: var(--primary-blue-color);

    @media screen and (max-width: $sm-breakpoint){
      font-size: 16px;
      text-align: center;
    }
  }

  &__subtitle {
    font-size: 14px;
    color: var(--primary-black-color);

    @media screen and (max-width: $sm-breakpoint){
      text-align: center;
    }
  }

  &__btn-repeat {
    @media screen and (max-width: $sm-breakpoint) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media screen and (max-width: $sm-breakpoint) {
    display: block;
  }
}

.button.repeat-button {
  margin-top: 15px;
  font-size: 14px;
  width: 260px;
}
