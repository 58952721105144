@import '../../assets/styles/media';

.block-title{
  display: flex;
  align-items: center;
  font-weight: bold;
  font-size: 36px;
  line-height: 150%;
  margin: 0;
  padding: 0;

  @media screen and (max-width: $sm-breakpoint){
    font-size: 24px;
  }
}

.block-description{
  display: block;
  font-size: 16px;
  line-height: 150%;
  margin: 1rem 0;
}