@import '../../../assets/styles/media';

.trainer-history-filters{
  width: 100%;
  max-width: 280px;

  .custom-select-wrapper{
    padding: 0;
  }

  @media screen and (max-width: $sm-breakpoint){
    max-width: 100%;
  }
}