@import './src/assets/styles/media';

.confirm-email-status {
  width: 545px;
  max-height: 322px;
  min-width: 252px;
  background: var(--primary-white-color);
  border-radius: 15px;
  margin-top: 200px;
  padding: 50px 38px 50px 50px;

  &__info {
    display: flex;

    @media screen and (max-width: $sm-breakpoint) {
      display: block;
    }
  }

  &__img {
    margin-right: 21px;

    @media screen and (max-width: $sm-breakpoint) {
      text-align: center;
    }
  }

  &__title {
    font-weight: bold;
    font-size: 18px;
    color: var(--primary-blue-color);

    @media screen and (max-width: $sm-breakpoint){
      text-align: center;
    }
  }

  &__subtitle {
    font-size: 14px;
    color: var(--primary-black-color);

    @media screen and (max-width: $sm-breakpoint) {
      text-align: center;
    }
  }

  &__btn-repeat {
    @media screen and (max-width: $sm-breakpoint) {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__btn-wrapper {
    margin-top: 42px;
    color: var(--primary-red-color);
    font-weight: 500;

    @media screen and (max-width: $sm-breakpoint) {
      text-align: center;
    }
  }

  @media screen and (max-width: $sm-breakpoint) {
    border-radius: 15px 15px 0 0;
    max-height: 100%;
    margin-top: 0;
    width: 100%;
    position: fixed;
    right: 0;
    left: 0;
    bottom: 0;
    top: 250px;
  }
}

.button.repeat-button {
  margin-top: 15px;
  font-size: 14px;
  width: 260px;
}