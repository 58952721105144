@mixin grid-styles() {
  display: grid;
  grid-gap: 1.5rem;
  grid-template-columns: 30% 40% 1fr;
  padding: 1rem 1.2rem;
}

.trainer-history-table{
  &__header{
    @include grid-styles();
    background: var(--primary-black-color);
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
  }
  &__header-col{
    display: flex;
    align-items: center;
    font-size: 14px;
    color: var(--primary-white-color);

    &--reverse{
      display: flex;
      justify-content: flex-end;
      text-align: right;
    }
  }

  &__body{
    border: 1px solid var(--primary-gray-color);
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    overflow: hidden;
  }

  &__row{
    @include grid-styles();

    &--dark{
      background: var(--light-gray-color);
    }
  }

  &__col{
    font-size: 16px;
    line-height: 150%;
    word-break: break-word;

    &--reverse{
      display: flex;
      justify-content: flex-end;
      flex-wrap: wrap;
      text-align: right;
    }

    &--name{
      word-break: break-all;
    }
  }
  &__came{
    font-weight: bold;
    margin-left: 10px;
  }
  &__gone{
    font-weight: bold;
    color: var(--primary-red-color);
    margin-left: 10px;
  }

  &__empty{
    width: 100%;
    padding: 20px;
    text-align: center;
    color: var(--dark-gray-color);
  }
}