@import '../../../assets/styles/media';

.active-orders {
  &__header {

    @media screen and (max-width: $sm-breakpoint) {
      padding: 0 1rem;
    }
  }

  &__body {
    padding-top: .9rem;
  }

  .all-orders-link {

    @media screen and (max-width: $sm-breakpoint) {
      padding: 0 1rem;
    }
  }
}