@import '../../assets/styles/media';

.form-auth {
  max-width: 645px;
  width: 100%;
  color: var(--primary-black-color);
  padding: 3rem;
  background: var(--primary-white-color);
  border-radius: 15px;
  margin-top: 35px;

  @media screen and (max-width: $sm-breakpoint) {
    height: 100%;
    overflow-y: auto;
    font-size: 0.9rem;
    padding: 2rem 1rem;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  .auth-header {
    &__item {
      margin-bottom: 1rem;
      font-size: 18px;
      font-weight: 500;

      @media screen and (max-width: $sm-breakpoint) {
        font-size: 1rem;
      }

      .new-user,
      .already-registered {
        &__text {
          margin-right: 5px;
        }
      }
    }
  }

  &__title {
    font-size: 36px !important;
    font-weight: 700 !important;

    @media screen and (max-width: $sm-breakpoint) {
      font-size: 1.5rem !important;
    }
  }

  &__body {
    margin-top: 2rem;

    @media screen and (max-width: $sm-breakpoint) {
      margin-top: 1rem;
    }

    .form-register {
      margin-top: -1rem;

      @media screen and (max-width: $sm-breakpoint) {
        margin-top: 1rem;
      }
    }
  }

  &__footer{
    width: 100%;
    padding-top: 3rem;
  }

  &__footer-links{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }
}

