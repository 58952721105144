@import '../../assets/styles/media';

.form-kid {
  max-width: 545px;

  &__group-inputs{
    display: grid;
    grid-gap: .5rem;
    grid-template-columns: repeat(3, 1fr);

    @media screen and (max-width: $sm-breakpoint){
      grid-gap: 0;
      grid-template-columns: repeat(1, 1fr);
    }
  }
}