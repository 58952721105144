@import '../../../assets/styles/media';

.reviews-sort-panel{
  width: 100%;
  padding-top: 35px;
  padding-bottom: 8px;
  border-bottom: 1px solid var(--light-gray-color);
  position: relative;

  &__wrap{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: $sm-breakpoint){
      flex-direction: column;
      align-items: flex-start;
    }
  }
  
  &__sort{
    width: 50%;
    position: relative;

    @media screen and (max-width: $sm-breakpoint){
      width: 100%;
    }
  }

  &__switch{
    width: 50%;
    display: flex;
    justify-content: flex-end;

    @media screen and (max-width: $sm-breakpoint){
      width: 100%;
      margin-top: 1rem;
      padding-left: .5rem;
      justify-content: flex-start;
    }
  }

  @media screen and (max-width: $sm-breakpoint){
    padding: 1rem 1rem 1rem .5rem;
  }
}