@import '../../../assets/styles/media';

.input-dadata-wrapper {
  color: var(--primary-black-color);
  font-size: .9rem;
  padding: 0.9rem 0;

  @media screen and (max-width: $sm-breakpoint) {
    padding: 0.6rem 0;
  }

  .react-dadata {

    &__input {
      border-radius: 15px;
      max-width: 545px;
      width: 100%;
      font-size: 18px;
      margin: 10px 0 0 0;
      padding: 1.9rem 1.25rem;
      background-color: var(--primary-white-color);
      border: 1px solid var(--primary-gray-color);
      transition: var(--all-transition);

      &:hover {
        border-color: var(--primary-black-color);
      }

      &:focus {
        border-color: var(--primary-black-color);
        box-shadow: unset;
      }

      &::placeholder {
        color: var(--primary-black-color);
        opacity: .5;
      }

      @media screen and (max-width: $sm-breakpoint) {
        padding: 1.7rem 1.25rem;
        font-size: 1rem;
      }
    }

    &__suggestions {
      max-height: 20vh;
      overflow-y: auto;
      z-index: 1000;

      @media screen and (max-width: $sm-breakpoint) {
        max-height: 160px;
      }
    }

    &__suggestion {
      transition: var(--all-transition) !important;

        &:hover {
          background: var(--light-gray-color) !important;
          cursor: pointer;
        }
      }
    }

  &.error {
    .react-dadata__input {
      border-color: var(--primary-red-color);
    }
  }

  .custom-label {
    font-weight: 500;

    @media screen and (max-width: $sm-breakpoint) {
      font-size: 0.9rem;
    }
  }

  .error-text {
    margin-top: 10px;
    color: var(--primary-red-color);
  }
}
