@import '../../assets/styles/media';

.kids{
  width: 100%;

  &__list{
    width: 100%;
    display: grid;
    grid-gap: 1rem;
    grid-template-columns: repeat(3, 1fr);
    padding-top: 1rem;

    @media screen and (max-width: $xxl-breakpoint){
      grid-template-columns: repeat(2, 1fr);
    }

    @media screen and (max-width: $md-breakpoint){
      grid-template-columns: repeat(1, 1fr);
    }
  }

  &__button{
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 30px;
    border-radius: 15px;
    border: 3px dashed var(--primary-gray-color);
    box-shadow: 0 5px 30px rgba(146, 146, 157, 0.1);
    background: var(--primary-white-color);
    transition: var(--all-transition);
    cursor: pointer;

    &:hover{
      border-color: var(--primary-red-color);
      background: var(--primary-red-color);

      .kids__button-icon svg path{
        fill: var(--primary-white-color);
      }
      .kids__button-text{
        color: var(--primary-white-color);
      }
    }

    @media screen and (max-width: $md-breakpoint) {
      width: 100%;
    }
  }
  &__button-icon{
    svg path{
      transition: var(--all-transition);
    }
  }
  &__button-text{
    font-weight: 500;
    font-size: 16px;
    text-align: center;
    margin-top: 5px;
    transition: var(--all-transition);
  }

  @media screen and (max-width: $sm-breakpoint) {
    padding: 0 1rem;
  }
}