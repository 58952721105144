@import '../../assets/styles/media';

.questionnaire {
  @media screen and (max-width: $sm-breakpoint) {
    padding: 0 1rem;
  }

  .questionnaire-step {
    &__title {
      font-size: 1.5rem;
      font-weight: 700;
      padding: 1rem 0;
      margin: 0;
    }

    &__kids {
      width: 100%;
      display: grid;
      grid-gap: 1rem;
      grid-template-columns: repeat(3, 1fr);
      padding-top: 1rem;

      @media screen and (max-width: $xxl-breakpoint){
        grid-template-columns: repeat(2, 1fr);
      }

      @media screen and (max-width: $md-breakpoint){
        grid-template-columns: repeat(1, 1fr);
      }
    }

    &__button {
      margin-right: 1rem;

      &:last-child {
        margin-right: 0;
      }
    }

    .questionnaire-button-wrapper,
    .questionnaire-button-kid-wrapper {
      display: inline-flex;

      @media screen and (max-width: $sm-breakpoint) {
        width: 100%;
        flex-direction: column;
      }

      &__item:first-child {
        margin-right: 1rem;
      }
    }

    .questionnaire-button-wrapper {
      padding-top: .9rem;
    }

    .questionnaire-button-kid-wrapper {
      padding-top: 1.8rem;
    }
  }

  .kid-add-card-questionnaire {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-width: 250px;
    padding: 30px;
    border-radius: 15px;
    border: 3px dashed var(--primary-gray-color);
    box-shadow: 0 5px 30px rgba(146, 146, 157, 0.1);
    background: var(--primary-white-color);
    transition: var(--all-transition);
    cursor: pointer;

    &:hover {
      border-color: var(--primary-red-color);
      background: var(--primary-red-color);

      .kid-add-card-questionnaire__button-icon svg path {
        fill: var(--primary-white-color);
      }
      .kid-add-card-questionnaire__button-text {
        color: var(--primary-white-color);
      }
    }

    @media screen and (max-width: $md-breakpoint) {
      width: 100%;
    }

    &__button-icon{
      svg path{
        transition: var(--all-transition);
      }
    }

    &__button-text{
      font-weight: 500;
      font-size: 16px;
      text-align: center;
      margin-top: 5px;
      transition: var(--all-transition);
    }
  }
}



