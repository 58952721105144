@import '../../assets/styles/media';

.delimiter{
  width: 100%;
  height: 1px;
  margin: 3rem 0;
  background: var(--light-gray-color);
  border: none;

  @media screen and (max-width: $sm-breakpoint){
    margin: 2rem 0;
  }
}