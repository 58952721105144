.order-total {
  &__item {
    display: flex;
    justify-content: space-between;

    &:nth-child(n+3) {
      .order-total__title,
      .order-total__sum {
        font-weight: 700;
      }
    }
  }

  &__title {
    color: #727275;
    font-weight: 500;
  }

  &__sum {
    font-weight: 500;
  }
}