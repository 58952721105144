@import '../../../assets/styles/media';

.modal-cancel-order {
  position: relative;
  max-height: 640px;
  width: 645px;
  padding: 50px 50px 50px 50px;
  background: var(--primary-white-color);
  border-radius: 15px;

  @media screen and (max-width: 700px){
    width: 545px;
  }

  @media screen and (max-width: $sm-breakpoint){
    width: 100%;
    max-height: calc(100vh - 60px);
    border-radius: 15px 15px 0 0;
    padding: 30px 15px;
  }

  &__close-icon {
    position: absolute;
    top: -45px;
    right: -9px;
    cursor: pointer;

    svg path {
      stroke: var(--primary-white-color);
    }

    @media screen and (max-width: $sm-breakpoint){
      right: 5px;
    }
  }

  &__title {
    font-size: 1.1rem;
    font-weight: bold;
    margin-bottom: 1rem;

    @media screen and (max-width: $sm-breakpoint){
      margin-bottom: 0;
    }
  }

  &__button-wrapper {
    display: flex;
    justify-content: flex-end;
  }

  &__button {
    width: 170px !important;
    height: 55px !important;

    @media screen and (max-width: $sm-breakpoint){
      width: 100% !important;
    }
  }
}