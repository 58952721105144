.sidebar-additional-blocks{
  width: 100%;
  display: flex;
  flex-direction: column;

  &__item{
    width: 100%;
    margin-top: 1.5rem;
    padding: 15px;
    box-shadow: 0 5px 30px rgba(146, 146, 157, 0.1);
    border-radius: 15px;
    background: var(--primary-white-color);
  }
}