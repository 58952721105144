@import '../../assets/styles/media';

@mixin visible-delete-icon() {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 7px;
  border-radius: 10px;
  position: absolute;
  top: 10px;
  right: 10px;
  background: var(--primary-white-color);
  z-index: 1;
  transition: var(--all-transition);
}

.kid-card-questionnaire {
  position: relative;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 30px 50px 30px 30px;
  background: var(--primary-white-color);
  box-shadow: 0 5px 30px rgba(146, 146, 157, 0.1);
  border-radius: 15px;
  border: 1px solid var(--primary-gray-color);
  transition: var(--all-transition);
  overflow: hidden;
  cursor: pointer;

  &:hover {
    border-color: var(--primary-red-color);

    .kid-card-questionnaire__trash-bin {
      @include visible-delete-icon();

      &:hover {
        background: var(--light-gray-color);
      }
    }

    .kid-card-questionnaire__icon svg path{
      fill: var(--primary-red-color);
    }
  }

  @media screen and (max-width: $md-breakpoint) {
    width: 100%;
    max-width: 100%;
    padding: 30px;
    justify-content: flex-start;
    margin-right: 0;
  }

  &__trash-bin {
    display: none;

    @media screen and (max-width: $lg-breakpoint) {
      @include visible-delete-icon();
    }
  }

  &__icon {
    width: 70px;
    min-width: 70px;
    height: 70px;
    display: flex;
    justify-content: center;
    align-items: center;

    svg path{
      transition: var(--all-transition);
    }
  }

  &__icon-item {
    width: 100%;

    div {
      width: 100%;
      display: flex;
      align-items: center;
    }

    svg {
      width: 100%;
      margin: auto;
    }
  }

  &__content {
    padding-left: 15px;
  }

  &__name {
    font-weight: 500;
    font-size: 18px;
  }

  &__age {
    color: var(--dark-gray-color);
    font-size: 16px;
    margin-top: 5px;
  }
}