.loader {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100%;
  background: rgba(0, 0, 0, .5);
  z-index: 99999;

  &--white {
    background: var(--primary-white-color);
  }

  &__icon svg {
    background: transparent !important;
  }
}