@import '../../../assets/styles/media';

.favorites-sort-panel{
  width: 100%;
  padding-bottom: 8px;
  border-bottom: 1px solid var(--light-gray-color);
  position: relative;

  &__wrap{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  &__filter-button{
    display: none;
    justify-content: center;
    align-items: center;
    padding: 8px;
    border: none;
    background: var(--light-gray-color);
    border-radius: 10px;
    cursor: pointer;

    &:active, &:hover{
      background: var(--primary-gray-color);
    }
    
    @media screen and (max-width: $lg-breakpoint) {
      display: flex;
    }
  }

  @media screen and (max-width: $sm-breakpoint){
    padding-right: 1rem;
    padding-left: .5rem;
  }
}