.bottom-menu{
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  background: var(--primary-white-color);
  box-shadow: 0 -5px 30px rgba(146, 146, 157, 0.1);
  border-radius: 10px 10px 0px 0px;
  z-index: 999;

  &__wrap{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
  }

  &__item{
    flex: 1;
    width: 100%;
    height: 100%;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    position: relative;

    &:before{
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      width: 100%;
      height: 3px;
      background: var(--primary-red-color);
      border-radius: 50px;
      opacity: 0;
    }

    &.active{
      .bottom-menu__item-icon svg{
        stroke: var(--primary-red-color);
      }
      .bottom-menu__item-text{
        color: var(--primary-red-color);
      }
      &:before{
        opacity: 1;
      }
    }
  }
  &__item-icon{
    width: 100%;
    height: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &__item-text{
    display: block;
    width: 100%;
    text-align: center;
    font-weight: 500;
    font-size: 12px;
    padding-top: 5px;
  }
}