.checkbox-button {
  position: relative;
  width: 70px;
  text-align: center;
  font-size: .9rem;
  border: 1px solid var(--primary-gray-color);
  border-radius: 16px;
  transition: var(--all-transition);

  &__active {
    background: var(--primary-red-color);
    border-color: var(--primary-red-color);
  }

  &__active > &__label {
    color: var(--primary-white-color);
  }

  &:hover {
    border-color: var(--primary-red-color);
  }

  &__label {
    padding: 10px 0;
    display: block;
    outline: none;
    border-radius: inherit;
    cursor: pointer;
  }

  &__input {
    position: absolute;
    top: 0;
    left: 50%;
    opacity: 0;
    outline: none;
    cursor: pointer;
  }
}