@import '../../../assets/styles/media';

.custom-radio-wrapper{
  padding: 0.9rem 0;

  @media screen and (max-width: $sm-breakpoint) {
    padding: 0.6rem 0;
  }

  .pretty .state label:before{
    border-color: var(--primary-gray-color) !important;
    transition: var(--all-transition);
  }
  .pretty.p-default input:hover ~ .state label:before{
    border-color: var(--primary-black-color) !important;
  }
  .pretty.p-default input:checked ~ .state label:before{
    border-color: var(--primary-red-color) !important;
  }
  .pretty.p-default input:checked ~ .state label:after{
    background-color: var(--primary-red-color) !important;
  }
}

.custom-radio-label{
  display: block;
  font-size: 14px;
  font-weight: 500;
}

.custom-radio-items{
  margin-top: 10px;
}

.custom-radio{
  margin-right: 1rem !important;
  .state{
    label {
      font-size: 18px;
    }
  }
}