.back-arrow{
  background: none;
  border: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-right: 1rem;
  padding-left: 0;
  cursor: pointer;

  svg path{
    transition: var(--all-transition);
  }

  &:hover{
    svg path{
      stroke: var(--primary-red-color);
    }
  }
}