@import '../../assets/styles/media';

.pagination {
  padding: 1.2rem 0;
  margin: 0;
  list-style-type: none;
  overflow-x: auto;
  white-space: nowrap;

  @media screen and (max-width: $sm-breakpoint) {
    padding: 1.2rem 1rem .7rem 1rem;
  }

  &__item {
    display: inline-block;
    position: relative;
    width: 40px;
    height: 40px;
    font-size: .9rem;
    border: 1px solid var(--primary-gray-color);
    border-radius: 16px;
    margin-right: 5px;
    transition: var(--all-transition);

    &:last-child {
      margin-right: 0;
    }

    &_active {
      border-color: var(--primary-red-color);
      background: var(--primary-red-color);
    }

    &_active > a {
      color: var(--primary-white-color);
    }

    &:hover {
      border-color: var(--primary-red-color);
    }
  }

  &__link {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    border-radius: inherit;
    cursor: pointer;
  }
}